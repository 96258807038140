<template>
  <div>
    <a-modal v-model="visible" title="Edit User" :dialogStyle="{ top: '10px' }">
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Submit
        </a-button>
      </template>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
            style="margin: unset;" ref="name" label="Name" prop="name">
              <a-input
                v-model="form.name"
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <!-- <a-form-model-item
            style="margin: unset;" ref="username" label="Username" prop="username">
              <a-input
                v-model="form.username"
                @blur="
                  () => {
                    $refs.username.onFieldBlur();
                  }
                "
              />
            </a-form-model-item> -->
            <!-- <a-form-model-item
            style="margin: unset;" label="Toko" v-if="user.role === 'Owner'">
              <a-select
                style="width: 50%;"
                v-model="form.toko_id"
              >
                <a-select-option v-for="(data, index) in datatoko" :key="index" :value="data.id">
                  {{ data.nama }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <!-- <a-form-model-item
            style="margin: unset;" ref="email" label="Email" prop="email">
              <a-input
                v-model="form.email"
                type="email"
                @blur="
                  () => {
                    $refs.email.onFieldBlur();
                  }
                "
              />
            </a-form-model-item> -->
            <a-form-model-item
            style="margin: unset;"
              ref="old_password"
              label="Password"
              prop="old_password"
            >
              <a-input
                v-model="form.old_password"
                type="password"
              />
            </a-form-model-item>
            <a-form-model-item
            style="margin: unset;"
              has-feedback
              label="New Password"
              prop="password"
            >
              <a-input
                v-model="form.password"
                type="password"
                autocomplete="off"
              />
            </a-form-model-item>
            <a-form-model-item
            style="margin: unset;"
              has-feedback
              label="Confirm Password"
              prop="password_confirmation"
            >
              <a-input
                v-model="form.password_confirmation"
                type="password"
                autocomplete="off"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'
import store from '@/store'

export default {
  name: 'editprofile',
  created() {
    this.user = store.state.user
    // this.form.ukmsys.store_id = this.user.city
    this.form.name = this.user.name
  },
  data() {
    const validatePass = (rule, value, callback) => {
      // if (value === '') {
      //   callback(new Error('Please input the password'))
      // } else {
      if (this.form.password_confirmation !== '') {
        this.$refs.ruleForm.validateField('checkPass')
      }
      callback()
      // }
    }
    const validatePass2 = (rule, value, callback) => {
      // if (value === '') {
      //   callback(new Error('Please input the password again'))
      // } else
      if (value !== this.form.password) {
        callback(new Error("Password doesn't match!"))
      } else {
        callback()
      }
    }
    // const checknumber = (rule, value, callback) => {
    //   if (!Number.isInteger(value)) {
    //     callback(new Error('Please input number'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      user: [],
      datatoko: [],
      roletags: false,
      form: {
        name: '',
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Please input name',
            trigger: 'blur',
          },
          {
            min: 5,
            max: 150,
            message: 'Length should be 3 to 150',
            trigger: 'blur',
          },
        ],
        username: [{ required: true, message: 'Please input username!' }],
        email: [
          {
            required: true,
            type: 'email',
            message: 'This is Not valid E-mail!',
          },
        ],
        old_password: [
          { message: 'Please input your curent Password!' },
        ],
        password: [{ validator: validatePass, trigger: 'change' }],
        password_confirmation: [
          { validator: validatePass2, trigger: 'change' },
        ],
        // pin: [
        //   {
        //     required: true,
        //     validator: checknumber,
        //     trigger: 'change',
        //   },
        // ],
      },
      loading: false,
      visible: false,
      datenow: null,
    }
  },
  methods: {
    async showModal() {
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          Modal.confirm({
            title: 'Are you sure ?',
            content: "You can't prevent this!!!",
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'No',
            onOk: async () => {
              var fd = {
                name: this.form.name,
                password_lama: this.form.old_password,
                password_baru: this.form.password,
              }
              var res = await lou.updateProfile(fd)
              if (res) {
                store.state.user.name = fd.name
                store.state.user.username = res.data.username
                // console.log('nstore', nstore)
                // console.log('toko', store.state.user.ukmsys.toko)
                this.visible = false
                this.resetForm(formName)
              }
            },
            onCancel: () => {
              console.log('Cancel')
              return false
            },
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>

<style></style>
