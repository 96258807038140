<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <div class="row">
        <div
          :class="
            showtips
              ? 'col-xs-8 col-sm-8 col-md-8 col-lg-8'
              : 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
          "
        >
          <a
            href="javascript:void(0)"
            target="_blank"
            rel="noopener noreferrer"
            :class="$style.logo"
          >
            {{settings.companyname}}
            <span></span>
          </a>
          <br />
          <p class="mb-0">
            Copyright © {{ currentDate.getFullYear() }}
            {{ settings.licencedby }} |
            <a href="javascript:void(0)" rel="noopener noreferrer">Privacy Policy</a> | Version
            Code: {{ versionCode }}
          </p>
        </div>
        <div :class="showtips ? 'col-xs-4 col-sm-4 col-md-4 col-lg-4' : ''">
          <a-popover v-model="popovervisible" trigger="click" v-if="showtips">
            <template slot="title">
              <strong>Tips/Rules</strong>
              <a-icon
                type="close"
                style="cursor: pointer"
                @click="popovervisible = false"
                class="pull-right"
              />
            </template>
            <template slot="content">
              <vue-custom-scrollbar
                :style="{
                  height:
                    settings.isMobileView || settings.isMenuUnfixed
                      ? 'calc(70vh - 64px)'
                      : 'calc(70vh - 110px)',
                  width:
                    settings.isMobileView || settings.isMenuUnfixed
                      ? 'calc(50vw - 64px)'
                      : 'calc(50vw - 110px)',
                }"
              >
                <vue-markdown
                  :key="componentKey"
                  :html="true"
                  :linkify="true"
                  :toc="true"
                  :source="tipscontent"
                  :show="true"
                  :breaks="true"
                  :emoji="true"
                  :typographer="true"
                  v-on:rendered="allRight"
                  v-on:toc-rendered="tocAllRight"
                ></vue-markdown>
                <!-- <div v-html="tipscontent"></div> -->
              </vue-custom-scrollbar>
            </template>
            <a-icon
              class="float-right mr-5"
              type="info-circle"
              style="font-size: 40px"
            />
            <!-- <mylottiecomp
              :options="defaultOptions"
              v-on:animCreated="handleAnimation"
              :height="'100px'" :width="'100px'"
            ></mylottiecomp> -->
            <!-- <a-button type="primary" class="float-right">
              Hover me
            </a-button> -->
          </a-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
// import mylottiecomp from '@/components/lou/lottie'
import animationData from '@/assets/animations/12246-info.json'
import VueMarkdown from 'vue-markdown'
import vueCustomScrollbar from 'vue-custom-scrollbar'
export default {
  computed: {
    ...mapState(['settings']),
  },
  mounted() {
    // console.log('footer location.hostname', location.hostname)
    // console.log('this.settings.companyname', this.settings.companyname)
    if (location.hostname.includes('dreo')) {
      this.dreodomain = true
    }
  },
  watch: {
    $route(to, from) {
      this.showtips = false
      // console.log('to', to)
      // console.log('to.meta.title', to.meta.title)
      // console.log('from', from)
      this.checkDocumentation(to, from)
    },
  },
  components: {
    // mylottiecomp,
    vueCustomScrollbar,
    VueMarkdown,
  },
  data: function () {
    return {
      dreodomain: false,
      popovervisible: false,
      showtips: false,
      tipscontent: '',
      docList: [],
      defaultOptions: { animationData: animationData },
      versionCode: this.$versionCode,
      currentDate: new Date(),
      componentKey: 0,
    }
  },
  methods: {
    async checkDocumentation(to, from) {
      var state = this.$store.state
      // console.log('state.settings.whatapp.appName', state.settings.whatapp.appName)
      // console.log('state.settings.whatapp.id', state.settings.whatapp.id)
      if (this.docList.length === 0) {
        var nindexeddb = await lou.getDataFromDb()
        var docIndexTarget = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allDocumentationFiles' +
              state.settings.whatapp.appName +
              state.settings.whatapp.id,
        )
        if (docIndexTarget >= 0) {
          this.docList = JSON.parse(nindexeddb[docIndexTarget].data)
          // console.log(
          //   'nindexeddb[docIndexTarget].data',
          //   nindexeddb[docIndexTarget].data,
          // )
          // console.log('this.docList1', this.docList)
        } else {
          var res = await lou.customUrlGet('doc', false, true)
          if (res) {
            var docdata = res.data
            var gridConfigData = {
              mykey:
                'allDocumentationFiles' +
                state.settings.whatapp.appName +
                state.settings.whatapp.id,
              data: JSON.stringify(docdata),
            }
            lou.addDataToDb(gridConfigData)
            this.docList = this.$g.clone(docdata)
          }
        }
      }
      // console.log('this.docList', this.docList)
      this.documentationExist(to, from, state)
    },
    documentationExist(to, from, state) {
      // var newpermissionsarr = []
      var theroute = []
      var thedata = {}
      var that = this
      var letoutthechildren = function (array, tom) {
        // console.log('array', array)
        var ret = false
        for (let i = 0; i < array.length; i++) {
          const element = array[i]
          if (element.url === tom.path) {
            ret = true
            theroute.push(i)
            thedata = that.$g.clone(element)
            return ret
          } else {
            if (
              element.children !== undefined &&
              element.children.length !== 0
            ) {
              ret = letoutthechildren(element.children, tom)
              if (ret) {
                // theroute.push(i)
                // thedata = that.$g.clone(element)
                return true
              }
            }
          }
        }
        // console.log('ret', ret)
        return ret
      }
      letoutthechildren(state.user.permissions, to)
      // console.log('theroute', theroute)
      // console.log('thedata', thedata)
      // console.log('retnya', retnya)
      // var targetMenu = state.user.permissions.findIndex(x => x.url === to.path)
      // console.log('state.user.permissions', state.user.permissions)
      // console.log('to.path', to.path)
      var theRDR = thedata // theRealDataRoute
      // console.log('theRDR', theRDR)
      // console.log('state.settings.whatapp.id', state.settings.whatapp.id)
      // console.log('this.docList', this.docList)
      var documentTargetIndex = this.docList.findIndex(
        (x) =>
          x.title === theRDR.title && x.app_id === state.settings.whatapp.id,
      )
      // console.log('this.docList', this.docList)
      // console.log('documentTargetIndex', documentTargetIndex)
      if (documentTargetIndex >= 0) {
        this.showtips = true
        this.tipscontent = this.docList[documentTargetIndex].content
      } else {
        this.showtips = false
        this.tipscontent = ''
      }
      // console.log('this.tipscontent', this.tipscontent)
    },
    allRight: function (htmlStr) {
      // console.log('markdown is parsed !')
    },
    tocAllRight: function (tocHtmlStr) {
      // console.log('toc is parsed :', tocHtmlStr)
      this.listToc = tocHtmlStr
    },
    handleAnimation(anim) {
      this.anim = anim
    },

    stop() {
      this.anim.stop()
    },

    play() {
      this.anim.play()
    },

    pause() {
      this.anim.pause()
    },

    onSpeedChange() {
      this.anim.setSpeed(this.animationSpeed)
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
