var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.footer},[_c('div',{class:_vm.$style.footerInner},[_c('div',{staticClass:"row"},[_c('div',{class:_vm.showtips
            ? 'col-xs-8 col-sm-8 col-md-8 col-lg-8'
            : 'col-xs-12 col-sm-12 col-md-12 col-lg-12'},[_c('a',{class:_vm.$style.logo,attrs:{"href":"javascript:void(0)","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.settings.companyname)+" "),_c('span')]),_c('br'),_c('p',{staticClass:"mb-0"},[_vm._v(" Copyright © "+_vm._s(_vm.currentDate.getFullYear())+" "+_vm._s(_vm.settings.licencedby)+" | "),_c('a',{attrs:{"href":"javascript:void(0)","rel":"noopener noreferrer"}},[_vm._v("Privacy Policy")]),_vm._v(" | Version Code: "+_vm._s(_vm.versionCode)+" ")])]),_c('div',{class:_vm.showtips ? 'col-xs-4 col-sm-4 col-md-4 col-lg-4' : ''},[(_vm.showtips)?_c('a-popover',{attrs:{"trigger":"click"},model:{value:(_vm.popovervisible),callback:function ($$v) {_vm.popovervisible=$$v},expression:"popovervisible"}},[_c('template',{slot:"title"},[_c('strong',[_vm._v("Tips/Rules")]),_c('a-icon',{staticClass:"pull-right",staticStyle:{"cursor":"pointer"},attrs:{"type":"close"},on:{"click":function($event){_vm.popovervisible = false}}})],1),_c('template',{slot:"content"},[_c('vue-custom-scrollbar',{style:({
                height:
                  _vm.settings.isMobileView || _vm.settings.isMenuUnfixed
                    ? 'calc(70vh - 64px)'
                    : 'calc(70vh - 110px)',
                width:
                  _vm.settings.isMobileView || _vm.settings.isMenuUnfixed
                    ? 'calc(50vw - 64px)'
                    : 'calc(50vw - 110px)',
              })},[_c('vue-markdown',{key:_vm.componentKey,attrs:{"html":true,"linkify":true,"toc":true,"source":_vm.tipscontent,"show":true,"breaks":true,"emoji":true,"typographer":true},on:{"rendered":_vm.allRight,"toc-rendered":_vm.tocAllRight}})],1)],1),_c('a-icon',{staticClass:"float-right mr-5",staticStyle:{"font-size":"40px"},attrs:{"type":"info-circle"}})],2):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }