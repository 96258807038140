var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout-sider',{class:{
    [_vm.$style.menu]: true,
    [_vm.$style.white]: _vm.settings.menuColor === 'white',
    [_vm.$style.gray]: _vm.settings.menuColor === 'gray',
    [_vm.$style.dark]: _vm.settings.menuColor === 'dark',
    [_vm.$style.unfixed]: _vm.settings.isMenuUnfixed,
    [_vm.$style.shadow]: _vm.settings.isMenuShadow,
  },attrs:{"width":_vm.settings.leftMenuWidth,"collapsible":_vm.settings.isMobileView ? false : true,"collapsed":_vm.settings.isMenuCollapsed && !_vm.settings.isMobileView},on:{"collapse":_vm.onCollapse}},[_c('div',{class:_vm.$style.menuOuter,style:({
      width:
        _vm.settings.isMenuCollapsed && !_vm.settings.isMobileView
          ? '80px'
          : _vm.settings.leftMenuWidth + 'px',
      height:
        _vm.settings.isMobileView || _vm.settings.isMenuUnfixed
          ? 'calc(100% - 64px)'
          : 'calc(100% - 110px)',
    })},[_c('div',{class:_vm.$style.logoContainer},[_c('div',{class:_vm.$style.logo},[_c('img',{staticClass:"mr-2 customiconclass",staticStyle:{"height":"24px","width":"24px"},attrs:{"src":_vm.settings.hosturl === 'kop.heasoft.com' ? 'resources/images/logo.png' : _vm.settings.whatapp !== null && Object.keys(_vm.settings.whatapp).length > 0 ? _vm.settings.whatapp.icon_url : 'resources/images/logo.png',"alt":_vm.settings.hosturl === 'kop.heasoft.com' ? 'Koperasi System' : _vm.settings.whatapp !== null && Object.keys(_vm.settings.whatapp).length > 0 ? _vm.settings.whatapp.appName : 'Banking App'}}),_c('div',{class:_vm.$style.name},[_vm._v(_vm._s(_vm.settings.hosturl === 'kop.heasoft.com' ? 'Koperasi System' : _vm.settings.whatapp !== null && Object.keys(_vm.settings.whatapp).length > 0 ? _vm.settings.whatapp.appName : 'Banking App'))])])]),_c('vue-custom-scrollbar',{style:({
        height:
          _vm.settings.isMobileView || _vm.settings.isMenuUnfixed
            ? 'calc(100vh - 64px)'
            : 'calc(100vh - 110px)',
      })},[_c('a-menu',{key:_vm.componentKey,class:_vm.$style.navigation,attrs:{"forceSubMenuRender":"","inlineCollapsed":_vm.settings.isMobileView ? false : _vm.settings.isMenuCollapsed,"mode":'inline',"selectedKeys":_vm.selectedKeys,"openKeys":_vm.openKeys,"inlineIndent":15},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event},"click":_vm.handleClick,"openChange":_vm.handleOpenChange}},[_vm._l((_vm.menuData),function(item,index){return [(!item.roles)?[(item.category)?_c('a-menu-item-group',{key:index},[_c('template',{slot:"title"},[_vm._v(_vm._s(item.title))])],2):_vm._e(),(item.children.length === 0 && !item.category)?_c('item',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style},on:{"click":_vm.toggleMobileMenu}}):_vm._e(),(item.children.length !== 0)?_c('sub-menu',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style},on:{"click":_vm.toggleMobileMenu}}):_vm._e()]:_vm._e()]})],2),_c('div',{class:_vm.$style.banner},[_c('p',[_vm._v(" Licensed to "),_c('br'),_vm._v(_vm._s(_vm.user.namabpr === '' ? _vm.laksdfjiw ? 'DREO System' : 'Heasoft Banking System' : _vm.user.namabpr)+" ")]),_c('a',{staticClass:"btn btn-sm btn-success btn-rounded px-3",attrs:{"href":"javascript:void(0)","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.user.namabpr === '' ? _vm.laksdfjiw ? 'DREO System' : 'Heasoft Banking System' : _vm.user.namabpr)+" ")])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }