<template>
      <!-- (menuInfo.roles && menuInfo.roles.includes(user.role)) ||
       || user.permissions.filter((element) => menuInfo.permissions.includes(element)) != '' -->
  <a-menu-item
    :key="menuInfo.key"
    v-bind="$props"
    v-on="$listeners"
    :disabled="menuInfo.disabled"
    v-if="
      (!menuInfo.permissions && !menuInfo.roles)
    "
  >
    <router-link v-if="menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{
        menuInfo.count
      }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
    </router-link>
    <span v-if="!menuInfo.url" :to="menuInfo.url">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{
        menuInfo.count
      }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
    </span>
  </a-menu-item>
</template>

<script>
import { Menu } from 'ant-design-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SubMenu',
  computed: {
    ...mapGetters('user', ['user']),
  },
  props: {
    ...Menu.Item.props,
    menuInfo: Object,
    styles: Object,
  },
}
</script>
