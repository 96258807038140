<template>
  <div id="app">
    <!-- <lockingComp /> -->
    <!-- <ModalIdle v-if="isIdle" /> -->
    <localization></localization>
  </div>
</template>

<style>

[data-kit-theme="default"] .style-chooser-pos-relative .vs__search::placeholder,
[data-kit-theme="default"] .style-chooser-pos-relative .vs__dropdown-toggle,
[data-kit-theme="default"] .style-chooser-pos-relative .vs__dropdown-menu {
  position: relative;
  background: #fff;
  border: 1px solid #e4e9f0;
  color: #394066;
  max-height: 250px !important;
  /* z-index: 9999999; */
  /* max-height: 300px !important; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

[data-kit-theme="default"] .style-chooser .vs__search::placeholder,
[data-kit-theme="default"] .style-chooser .vs__dropdown-toggle,
[data-kit-theme="default"] .style-chooser .vs__dropdown-menu {
  background: #fff;
  border: 1px solid #e4e9f0;
  color: #394066;
  /* z-index: 9999999; */
  max-height: 250px !important;
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

[data-kit-theme="default"] .style-chooser .vs__clear,
[data-kit-theme="default"] .style-chooser .vs__open-indicator {
  fill: #394066;
}

[data-kit-theme="dark"] .style-chooser-pos-relative .vs__search::placeholder,
[data-kit-theme="dark"] .style-chooser-pos-relative .vs__dropdown-toggle,
[data-kit-theme="dark"] .style-chooser-pos-relative .vs__dropdown-menu {
  border: 1px solid #232135;
  position: relative;
  background: #232135;
  /* color: #aeaee0; */
  max-height: 250px !important;
  /* z-index: 9999999; */
  /* max-height: 300px !important; */
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}
[data-kit-theme="dark"] .style-chooser-pos-relative .vs__clear,
[data-kit-theme="dark"] .style-chooser-pos-relative .vs__open-indicator {
  fill: #aeaee0;
}

[data-kit-theme="dark"] .vs__selected {
  color: #aeaee0 !important;
}

[data-kit-theme="dark"] .vs__search:focus {
  border: 1px solid #78a3ff;
  border-radius: 5px;
}

[data-kit-theme="dark"] .style-chooser .vs__search::placeholder,
[data-kit-theme="dark"] .style-chooser .vs__dropdown-toggle,
[data-kit-theme="dark"] .style-chooser .vs__dropdown-menu {
  background: #232135;
  border: 1px solid #232135;
  /* color: #394066; */
  /* z-index: 9999999; */
  max-height: 250px !important;
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

[data-kit-theme="dark"] .style-chooser .vs__clear,
[data-kit-theme="dark"] .style-chooser .vs__open-indicator {
  fill: #aeaee0;
}

.vs__selected-options {
  flex-wrap: nowrap !important;
}

.ant-select-dropdown-menu-item {
  max-width: 240px !important;
  overflow-wrap: break-word !important;
  white-space: normal !important;
}

.drag-container {
  width: 100%;
  height: 500px;
  border-radius: 5px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09);
}

.dragged-element {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  text-align: center;
  transition: all 0.4s linear;
  transition-property: background-color;
}

.ant-select {
  min-width: 100px !important;
}

.green {
  background-color: rgba(76, 175, 80, 0.3);
}
/* @media only screen and (max-device-width: 640px) {
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left: unset !important;
    padding-right: unset !important;
  }
} */

/* @media only screen and (max-device-width: 768px) {
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left: unset !important;
    padding-right: unset !important;
  }
} */
.my-grid-class {
  height: 400px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.ant-layout-header {
  line-height: 13px !important;
}
.ant-input {
  height: 28px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.ant-input-search-button {
  height: 28px !important;
}

.ant-select-selection--single {
  height: 28px !important;
}

.ant-select-selection__rendered {
  line-height: 26px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.btn.ant-btn {
  height: 28px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  font-size: 12px !important;
}

/* .ant-btn {
  font-size: 14px !important;
  height: 30px !important;
} */

.ant-table-row {
  line-height: 1;
}

[data-kit-theme="dark"] .ant-card-head {
  border-bottom-color: #828282 !important;
}

.ant-card-body {
  /* padding: unset !important; */
}

/* css untuk top bar alasan saya taruh sini module scss tidak mau diisi css ataupun scss */
.mypopovercontent:hover {
  background-color: lightgrey;
  border-radius: 4px;
}

label {
  margin-bottom: 0 !important; /* Sebelumnya 0.5rem */
}
</style>

<script>
// import ModalIdle from '@/components/lou/idleModal'
// import * as lou from '@/services/data/lou'
// import lockingComp from '@/components/lou/lockComponent'
import { mapState } from 'vuex'
import Localization from '@/localization'

// var devtools = function() {}
// devtools.toString = function() {
//   if (!this.opened) {
//     alert('Please close the devtools!')
//   }
//   this.opened = true
// }

// console.log('%c', devtools)
// devtools.opened will become true if/when the console is opened

export default {
  name: 'app',
  data() {
    return {
      time: 10000,
      timerId: null,
    }
  },
  components: {
    Localization,
    // lockingComp,
    // ModalIdle,
  },
  computed: {
    ...mapState(['settings', 'idleVue']),
    ...mapState('user', ['authorized']),
    nextRoute() {
      return this.$route.query.redirect || '/'
    },
    currentRoute() {
      return this.$route.path
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle
    },
  },
  mounted() {
    var firstTime = localStorage.getItem('firstTime')
    // console.log('firstTime', firstTime)
    if (firstTime === null) {
      // lou.shownotif('Tips!', 'Anda dapat merubah periode dengan menekan text periode saat ini di header!', 'info', 10)
      localStorage.setItem('firstTime', false)
    }
    this.$store.dispatch('user/LOAD_CURRENT_ACCOUNT')
    this.$store.commit('SET_PRIMARY_COLOR', {
      color: this.settings.primaryColor,
    })
    this.$store.commit('SET_THEME', { theme: this.settings.theme })
    if (!window.indexedDB) {
      console.log(
        "Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.",
      )
    }
    // console.log('app location.hostname', location.hostname)
    if (location.hostname.includes('dreo')) {
      this.settings.logo = 'DREO System'
    }
  },
  watch: {
    'idleVue.isIdle': {
      handler: function(newValue, oldValue) {
        if (newValue === true) {
          this.settings.isLocked = false
        }
      },
      deep: true,
    },
    // isIdle: function () {
    //   const nkey = this.$uuid.v4()
    //   if (this.isIdle) {
    //     this.startInterval()
    //     this.$notification.info({
    //       message: 'Locked!',
    //       description: 'This app will lock it self when there\'s no activity. Countdown started 10 seconds from now.',
    //       key: nkey,
    //       duration: 9,
    //     })
    //     // console.log(
    //     //   'this.$store.state.idleVue',
    //     //   this.$store.state.idleVue.isIdle,
    //     // )
    //   } else {
    //     this.stopInterval()
    //   }
    // },
    '$store.state.settings.theme'(theme) {
      this.$store.commit('SET_THEME', { theme })
    },
    authorized(authorized) {
      if (authorized && this.currentRoute === '/auth/login') {
        this.$router.replace(this.nextRoute)
      }
    },
    $route(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  methods: {
    stopInterval() {
      clearInterval(this.timerId)
    },
    startInterval() {
      this.timerId = setInterval(() => {
        this.time -= 1000
        // console.log('this.time', this.time)
        if (!this.$store.state.idleVue.isIdle) {
          clearInterval(this.timerId)
        }

        if (this.time < 1) {
          // Your logout function should be over here
          // alert('logout user....')
          clearInterval(this.timerId)
          this.time = 10000
          this.$store.commit('CHANGE_SETTING', { 'isLocked!': false })
          this.$router.push('/auth/lockscreen')
        }
      }, 1000)
    },
  },
}
</script>
