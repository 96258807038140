var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',[_c('a-layout-content',[_c('cui-sidebar'),_c('div',{class:{
        [_vm.$style.container]: true,
        cui__layout__squaredBorders: _vm.settings.isSquaredBorders,
        cui__layout__cardsShadow: _vm.settings.isCardShadow,
        cui__layout__borderless: _vm.settings.isBorderless,
        [_vm.$style.white]: _vm.settings.authPagesColor === 'white',
        [_vm.$style.gray]: _vm.settings.authPagesColor === 'gray',
      },style:({backgroundImage: _vm.settings.authPagesColor === 'image' ? `url(resources/images/content/photos/7.jpg)` : 'none'})},[_c('div',{class:{
        [_vm.$style.topbar]: true,
        [_vm.$style.topbarGray]: _vm.settings.isGrayTopbar,
      }},[_c('div',{class:_vm.$style.logoContainer},[_c('div',{class:_vm.$style.logo},[_c('img',{staticClass:"mr-2",attrs:{"src":"resources/images/logo.png","alt":"KSP Syariah"}}),_c('div',{class:_vm.$style.name},[_vm._v(_vm._s(_vm.settings.logo))])])]),_c('div',{staticClass:"d-none d-sm-block"},[_c('span',{staticClass:"mr-2"},[_vm._v("Don't have an account? Contact an admin ")])])]),_c('div',{class:_vm.$style.containerInner},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1),_c('div',{staticClass:"mt-auto pb-5 pt-5"},[_c('ul',{staticClass:"list-unstyled d-flex mb-0 flex-wrap justify-content-center",class:[_vm.$style.footerNav]},[_c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"javascript: void(0);"}},[_vm._v("Terms of Use")])]),_c('li',{staticClass:"active list-inline-item"},[_c('a',{attrs:{"href":"javascript: void(0);"}},[_vm._v("Compliance")])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"javascript: void(0);"}},[_vm._v("Confidential Information")])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"javascript: void(0);"}},[_vm._v("Support")])]),_c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"javascript: void(0);"}},[_vm._v("Contacts")])])]),_c('div',{staticClass:"text-center"},[_vm._v(" Copyright © "+_vm._s(_vm.currentDate.getFullYear())+" "+_vm._s(_vm.settings.licencedby)+" | "),_c('a',{attrs:{"href":"javascript:void(0)","rel":"noopener noreferrer"}},[_vm._v("Privacy Policy")])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }