<template>
  <div :class="$style.container" :key="componentKey">
    <template v-for="item in favs">
      <a-tooltip placement="bottom" :key="item.key">
        <template slot="title">{{
          item.title
        }}</template>
        <span>
          <router-link :to="item.url" :class="$style.item" class="mr-3">
            <i class="fe" :class="[$style.icon, item.icon]" />
          </router-link>
        </span>
      </a-tooltip>
    </template>
    <a-tooltip placement="bottom">
      <template slot="title">Bookmarks</template>
      <span :class="$style.item">
        <a-dropdown
          :visible="dropdownVisible"
          :trigger="['click']"
          placement="bottomLeft"
        >
          <div :class="$style.dropdown" @click="toggleDropdown">
            <i class="fe fe-star" :class="$style.icon"></i>
          </div>
          <div slot="overlay" @keydown.esc="toggleDropdown()">
            <div class="card cui__utils__shadow width-350">
              <div class="card-body p-1">
                <div class="p-2">
                  <a-input
                    v-model="searchText"
                    @change="filterPagesList"
                    allowClear
                    :placeholder="$t('topBar.findPages')"
                    ref="cSearch"
                  />
                </div>
                <div class="height-200">
                  <vue-custom-scrollbar :style="{ height: '100%' }">
                    <div class="px-2 pb-2" :key="componentKey">
                      <template v-for="item in filteredPagesList">
                        <router-link
                          :key="item.key"
                          :to="item.url"
                          :class="$style.link"
                        >
                          <div
                            :class="{
                              [$style.setIcon]: true,
                              [$style.setIconActive]: item.isActive,
                            }"
                            @click="(e) => setFav(e, item)"
                          >
                            <i class="fe fe-star" />
                          </div>
                          <span @click="closeDropdown">
                            <i class="mr-2 fe" :class="item.icon" />
                            {{ item.title }}
                          </span>
                        </router-link>
                      </template>
                    </div>
                  </vue-custom-scrollbar>
                </div>
                <div class="p-2">
                  <a-button
                    type="primary"
                    ref="closeDropdownmode"
                    @click="toggleDropdown"
                    >Close</a-button
                  >
                </div>
              </div>
            </div>
          </div>
        </a-dropdown>
      </span>
    </a-tooltip>
    <span class="ml-1">Menu</span>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { mapState, mapGetters } from 'vuex'
import store from 'store'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import { getMenuData } from '@/services/menu'

export default {
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
  },
  components: {
    vueCustomScrollbar,
  },
  data: function () {
    return {
      dropdownVisible: false,
      searchText: '',
      favs: store.get('app.topbar.favs') || [],
      pagesList: [],
      menuData: getMenuData,
      rawMenuData: [],
      filteredPagesList: [],
      componentKey: 0,
    }
  },
  mounted() {
    this.getAppList()
    this.getDataMenu()
  },
  watch: {
    'settings.whatapp'() {
      // console.log('this.settings.whatapp', this.settings.whatapp)
      this.favs = []
      this.getDataMenu()
    },
    menuData() {
      this.componentKey += 1
    },
  },
  methods: {
    async getDataMenu() {
      // console.log('getdataMenu')
      // console.log('this.user.permissions', this.user.permissions)
      // console.log('this.user.showheader.toString()', this.user.showheader.toString())
      var permissions = this.user.permissions
      // var permissions = JSON.parse(localStorage.getItem('permissions' + this.settings.whatapp.appName.toLowerCase() + this.user.showheader.toString()))
      // console.log('this.settings.whatapp.appName.toLowerCase()', this.settings.whatapp.appName.toLowerCase())
      // console.log('permissions', this.user.permissions)
      if (permissions === 'undefined' || permissions === null) {
        var resp = await lou.customUrlGet('auth/permissions', false, true)
        if (resp) {
          localStorage.setItem('permissions' + this.settings.whatapp.appName.toLowerCase() + this.user.showheader.toString(), JSON.stringify(resp.data))
        }
        this.rawMenuData = resp.data
      } else {
        this.rawMenuData = permissions
      }
      this.user.permissions = this.rawMenuData
      this.pushMenu()
    },
    pushMenu() {
      var oldData = {}
      // var menuDouble = 0
      var ndata = []
      for (let i = 0; i < this.rawMenuData.length; i++) {
        const element = this.rawMenuData[i]
        if (element === oldData) {
          // menuDouble += 1
        } else {
          ndata.push(element)
        }
        oldData = element
      }
      // console.log('Menu Double: ', menuDouble)
      this.menuData = ndata

      this.loadPagesList()
      this.filterPagesList()
      this.componentKey += 1
    },
    getAppList() {
      // if (this.settings.whatapp.appName.toLowerCase() === 'LBBPRS') {
      this.$store.commit('CHANGE_SETTING', { setting: 'whatapp', value: this.user.defaultApp })
      // }
      // console.log('localStorage.getItem(appList)', localStorage.getItem('appList'))
      var appList = localStorage.getItem('appList')
      // console.log('this.user.appList', this.user.appList)
      // console.log('appList', appList)
      if (appList === 'undefined' || appList === null) {
        localStorage.setItem('appList', JSON.stringify(this.user.appList))
      } else {
        this.user.appList = JSON.parse(appList)
      }
    },
    closeDropdown() {
      this.dropdownVisible = false
    },
    loadPagesList() {
      const pagesList = () => {
        // console.log('this.menuData', this.menuData)
        const menuData = this.menuData
        const _menuData = JSON.parse(JSON.stringify(menuData))
        const flattenItems = (items, key) =>
          items.reduce((flattenedItems, item) => {
            if (item.category) {
              return flattenedItems
            }
            if (item.key === 'nestedItem1' || item.disabled) {
              // skip unwanted items
              return flattenedItems
            }
            if (item[key].length !== 0) {
              const items = item[key].map((child) => {
                child.icon = item.icon
                return child
              })
              return flattenedItems.concat(flattenItems(items, key))
            }
            flattenedItems.push(item)
            return flattenedItems
          }, [])
        return flattenItems(_menuData, 'children')
      }
      this.pagesList = pagesList()
    },
    filterPagesList() {
      const pagesList = this.pagesList
      const favs = this.favs
      const _searchText = this.searchText ? this.searchText.toUpperCase() : ''
      // console.log('_searchText', _searchText)
      const getFilteredPageList = () => {
        const list = []
        pagesList.forEach((item) => {
          const isActive = favs.some((child) => child.url === item.url)
          if (!item.title.toUpperCase().includes(_searchText) && _searchText) {
            return null
          }
          item.isActive = isActive
          list.push(item)
          return null
        })
        return list
      }
      this.filteredPagesList = getFilteredPageList()
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible
      if (this.dropdownVisible) {
        setTimeout(() => this.$refs.cSearch.focus(), 300)
      }
    },
    setFav(e, item) {
      e.preventDefault()
      e.stopPropagation()
      const favs = this.favs
      const isActive = favs.some((child) => child.url === item.url)
      if (isActive) {
        const filtered = favs.filter((child) => child.url !== item.url)
        store.set('app.topbar.favs', filtered)
        this.favs = filtered
        this.filterPagesList()
        return
      }
      if (favs.length >= 3) {
        this.$message.info('Only three pages can be added to your bookmarks.')
        return
      }
      const items = [...favs]
      items.push(item)
      store.set('app.topbar.favs', items)
      this.favs = items
      this.filterPagesList()
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
