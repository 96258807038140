<template>
  <div
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
    }"
  >
    <div :class="$style.logoContainer">
      <div :class="$style.logo">
        <img src="resources/images/logo.png" class="mr-2" alt="Labul Icon" />
        <div :class="$style.name">{{ settings.logo }}</div>
        <!-- <div v-if="settings.logo === 'KSP-Syariah'" :class="$style.descr"></div> -->
      </div>
    </div>
    <div :class="$style.navigation">
      <a-menu :mode="'horizontal'" :selectedKeys="selectedKeys" @click="handleClick">
        <template v-for="item in menuData">
          <template v-if="!item.roles || item.roles.includes(user.role)">
            <item
              v-if="item.children.length === 0 && !item.category"
              :menu-info="item"
              :styles="$style"
              :key="item.key"
            />
            <sub-menu v-if="item.children.length !== 0" :menu-info="item" :styles="$style" :key="item.key" />
          </template>
        </template>
      </a-menu>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { mapState, mapGetters } from 'vuex'
import store from 'store'
import find from 'lodash/find'
// import { getMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'menu-top',
  components: { SubMenu, Item },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.openKeys = store.get('app.menu.openedKeys') || []
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    // console.log('this.$store.state.user.permissions', this.$store.state.user.permissions)
    this.getAppList()
    this.setSelectedKeys()
  },
  data() {
    return {
      menuData: [],
      selectedKeys: [],
      openKeys: [],
      rawMenuData: [],
      componentKey: 0,
    }
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    'settings.whatapp'() {
      // console.log('this.settings.whatapp', this.settings.whatapp)
      this.getDataMenu()
    },
    $route() {
      this.setSelectedKeys()
    },
  },
  methods: {
    pushMenu() {
      var oldData = {}
      // var menuDouble = 0
      var ndata = []
      // console.log('this.rawMenuData', this.rawMenuData)
      for (let i = 0; i < this.rawMenuData.length; i++) {
        const element = this.rawMenuData[i]
        // console.log('element', element.key)
        if (element === oldData) {
          // menuDouble += 1
        } else {
          ndata.push(element)
        }
        oldData = element
      }
      // console.log('Menu Double: ', menuDouble)
      this.menuData = []
      this.menuData = ndata
      // this.componentKey += 1
    },
    async getDataMenu() {
      // console.log('getdataMenu')
      // console.log('this.user.permissions', this.user.permissions)
      // console.log('this.user.showheader.toString()', this.user.showheader.toString())

      try {
        var permissions = JSON.parse(localStorage.getItem('permissions' + this.settings.whatapp.appName.toLowerCase() + this.user.showheader.toString()))
      } catch (e) {
        // console.log('e', e)
      }
      // console.log('this.settings.whatapp.appName.toLowerCase()', this.settings.whatapp.appName.toLowerCase())
      // console.log('permissions', permissions)
      if (permissions === 'undefined' || permissions === null || permissions.length === 0) {
        var resp = await lou.customUrlGet('auth/permissions', false, true)
        if (resp) {
          localStorage.setItem('permissions' + this.settings.whatapp.appName.toLowerCase() + this.user.showheader.toString(), JSON.stringify(resp.data))
        }
        this.rawMenuData = resp.data
      } else {
        this.rawMenuData = permissions
      }
      this.user.permissions = this.rawMenuData
      this.pushMenu()
    },
    getAppList() {
      // if (this.settings.whatapp.appName.toLowerCase() === 'LBBPRS') {
      this.$store.commit('CHANGE_SETTING', { setting: 'whatapp', value: this.user.defaultApp })
      // }
      // console.log('localStorage.getItem(appList)', localStorage.getItem('appList'))
      var appList = localStorage.getItem('appList')
      // console.log('this.user.appList', this.user.appList)
      // console.log('appList', appList)
      if (appList === undefined || appList === null) {
        localStorage.setItem('appList', JSON.stringify(this.user.appList))
      } else {
        this.user.appList = JSON.parse(appList)
      }
      this.getDataMenu()
    },
    handleClick(e) {
      if (e.key === 'settings') {
        this.$store.commit('CHANGE_SETTING', { setting: 'isSettingsOpen', value: true })
        return
      }
      store.set('app.menu.selectedKeys', [e.key])
      this.selectedKeys = [e.key]
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])
      this.selectedKeys = selectedItem ? [selectedItem.key] : []
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
