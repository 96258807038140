<template>
  <div :class="$style.topbar">
    <div class="mr-4" v-if="settings.isMobileView">
      <i
        class="fa fa-bars"
        style="cursor: pointer;"
        @click="toggleMobileMenu"
      ></i>
    </div>
    <div :class="!settings.isMobileView ? 'mr-4 d-none d-md-block' : 'mr-4'">
      <cui-fav-pages />
    </div>
    <!-- <div class="mr-4 d-none d-md-block"> -->
    <!-- <cui-breadcrumbs /> -->
    <!-- <cui-search /> -->
    <!-- </div> -->
    <div class="mr-4 d-none d-md-block">
      <!-- <cui-issues-history /> -->
    </div>
    <div class="mb-0 mr-auto d-xl-block d-none">
      <!-- <cui-project-management /> -->
    </div>
    <!-- <div class="mr-4 d-none d-sm-block">
      <cui-language-switcher />
    </div> -->
    <!-- <div class="mr-4 d-none d-md-block">
      <clock />
    </div> -->
    <!-- <div
      :style="settings.isMobileView ? 'width: 55%;' : ''"
      :class="!settings.isMobileView ? 'mr-4 d-none d-md-block' : 'mr-4'"
    > -->
    <!-- <center v-if="settings.isMobileView">
        <strong>{{ storename }}</strong>
      </center> -->
    <!-- </div> -->
    <div :style="settings.isMobileView ? 'width: 75%;' : ''">
      <a-tooltip
        placement="bottom"
        :trigger="['click']"
        v-if="settings.isMobileView"
      >
        <template slot="title">
          <strong class="mr-2" v-if="user.showheader">App: </strong>
          <a-select
            v-if="user.showheader"
            v-model="defaultAppValue"
            style="width: 150px;"
            :dropdownStyle="{
              minWidth: 200,
              width: '100%',
            }"
            @change="selectApps"
          >
            <a-select-option
              v-for="(data, index) in user.appList"
              :key="index"
              :value="index"
              >{{ data.appName }}</a-select-option
            >
          </a-select>
          <br />
          <strong class="mt-2" v-if="user.defaultApp.hide_periode === false"
            >Kode:
          </strong>
          <a-select
            v-if="user.showheader && user.defaultApp.hide_periode === false"
            v-model="kodeCabang"
            show-search
            class="mt-2"
            placeholder="Pilih Kode Cabang"
            option-filter-prop="children"
            style="width: 150px;"
            :filter-option="filterOption"
            @change="filterKodeCabang"
          >
            <a-select-option value="all">
              Kode: All
            </a-select-option>
            <a-select-option
              v-for="(data, index) in user.cabangList"
              :key="index"
              :value="data.kode"
            >
              Kode: {{ data.kode }}
            </a-select-option>
          </a-select>
          <br />
          <strong
            v-if="!editAble && user.defaultApp.hide_periode === false"
            class="text-primary mt-4"
            @click="editAble = true"
            >{{
              user.periode
                ? "Periode: " +
                  moment(this.$store.state.user.periode, "YYYY-MM-DD").format(
                    "DD-MM-YYYY"
                  )
                : ""
            }}</strong
          >
          <a-date-picker
            class="mt-2"
            style="width: 100px;"
            v-else-if="editAble && user.defaultApp.hide_periode === false"
            v-model="input.periode"
            :allowClear="false"
            format="DD-MM-YYYY"
            @change="changePeriode"
          />
          <!-- <strong class="mr-4 text-warning" v-if="!editAble" @mouseover="editAble = true">{{ kodeCabang || 'all' }}</strong> -->
          <!-- v-else -->
          <br />
          <strong class="mr-2 mt-2">{{ user.sandibpr }}</strong>
          <strong class="mr-2 mt-2">-</strong>
          <strong
            style="overflow: hidden; text-overflow: ellipsis;"
            class="mr-4 mt-2"
            >{{ user.namabpr }}</strong
          >
        </template>
        <center>Additional Info...</center>
      </a-tooltip>
      <a-modal
        v-model="billeruidvisible"
        :dialogStyle="{ top: '10px' }"
        :width="300"
      >
        <template slot="title">
          <strong>Biller Set UID and PIN</strong>
        </template>
        <template slot="footer">
          <div v-if="びっぇ">
            <a-button class="btn btn-outline-dark"  @click="びっぇ = !びっぇ">Cancel</a-button>
            <a-button class="btn btn-outline-primary" @click="settingBillerUid">Process</a-button>
          </div>
          <a-button class="btn btn-outline-warning" v-if="!びっぇ" @click="びっぇ = !びっぇ">Edit</a-button>
        </template>
        <div class="row" v-if="びっぇ">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label>Uid</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-input v-model="form.uid" @enter="settingBillerUid"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Pin</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input v-model="form.pin" @enter="settingBillerUid" style="-webkit-text-security: square;"></a-input>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Active UID: </strong> <label>{{ settings.tempAppData.uid }}</label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <strong>Active PIN: </strong> <label>******</label>
            </div>
        </div>
      </a-modal>
      <strong
        class="mr-3 text-primary"
        style="cursor: pointer;"
        @click="billeruidvisible = !billeruidvisible"
        v-if="
          settings.whatapp.appKey === 'bahari-biller'
        "
        ><a-icon type="setting" class="mr-1" />Set UID</strong
      >
      <a-modal
        v-model="carinasabahvisible"
        :dialogStyle="{ top: '10px' }"
        :footer="null"
        :width="800"
      >
        <template slot="title">
          <strong>{{ carinasabahtitle }} </strong>
          <label style="margin: unset;" v-if="settings.selectedRekening !== ''">
            | Active Rekening: {{ settings.selectedRekening }}</label
          >
        </template>
        <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
        <lou-cari-nasabah />
      </a-modal>
      <strong
        class="mr-3 text-primary"
        style="cursor: pointer;"
        @click="carinasabahvisible = !carinasabahvisible"
        v-if="
          settings.whatapp.dbname === 'bprtest' ||
          (settings.whatapp.dbname === 'koperasi' && settings.whatapp.appKey !== 'project_a' && settings.whatapp.appKey !== 'school-management-key' &&
            settings.selectedRekening === '' && settings.whatapp.appKey !== 'koperasi-syariah')
        "
        ><a-icon type="search" class="mr-1" /> Cari nasabah</strong
      >
      <strong
        class="mr-3 text-primary"
        style="cursor: pointer;"
        @click="carinasabahvisible = !carinasabahvisible"
        v-else-if="
          settings.whatapp.dbname === 'bprtest' ||
          (settings.whatapp.dbname === 'koperasi' &&
            settings.selectedRekening !== '')
        "
        >Active Rekening: {{ settings.selectedRekening }}</strong
      >
      <!-- <img src="@/assets/icons/show-apps-button-black.png"/> -->
      <!-- <strong class="mr-2" v-if="user.showheader && !settings.isMobileView"
        >App:
      </strong>
      <a-select
        v-if="user.showheader && !settings.isMobileView"
        class="mr-5"
        v-model="defaultAppValue"
        show-search
        option-filter-prop="children"
        :filter-option="filterOption"
        style="width: 120px;"
        :dropdownStyle="{
          minWidth: 200,
          width: '100%',
        }"
        @change="selectApps"
      >
        <a-select-option
          v-for="(data, index) in user.appList"
          :key="index"
          :value="index"
          >{{ data.appName }}</a-select-option
        >
      </a-select> -->
      <strong
        class="mr-2"
        v-if="!settings.isMobileView && !settings.isMobileView"
        >{{ user.sandibpr }}</strong
      >
      <strong
        class="mr-2"
        v-if="!settings.isMobileView && !settings.isMobileView"
        >-</strong
      >
      <strong
        style="overflow: hidden; text-overflow: ellipsis;"
        class="mr-4"
        v-if="!settings.isMobileView"
        >{{ user.namabpr }}</strong
      >
      <strong
        v-if="
          !editAble &&
          !settings.isMobileView &&
          user.defaultApp.hide_periode === false
        "
        class="ml-3 mr-3 text-primary"
        @click="editAble = true"
        >{{
          user.periode
            ? moment(this.$store.state.user.periode, "YYYY-MM-DD").format(
                "DD-MM-YYYY"
              )
            : ""
        }}</strong
      >
      <a-date-picker
        class="ml-2 mr-4"
        style="width: 100px;"
        v-else-if="
          editAble &&
          !settings.isMobileView &&
          user.defaultApp.hide_periode === false
        "
        v-model="input.periode"
        :allowClear="false"
        format="DD-MM-YYYY"
        @change="changePeriode"
      />
      <!-- <strong class="mr-4 text-warning" v-if="!editAble" @mouseover="editAble = true">{{ kodeCabang || 'all' }}</strong> -->
      <!-- v-else -->
      <a-select
        v-if="
          user.showheader &&
          !settings.isMobileView &&
          user.defaultApp.hide_periode === false
        "
        v-model="kodeCabang"
        show-search
        class="mr-4"
        placeholder="Pilih Kode Cabang"
        option-filter-prop="children"
        style="width: 90px;"
        :filter-option="filterOption"
        @change="filterKodeCabang"
      >
        <a-select-option value="all">
          Kode: All
        </a-select-option>
        <a-select-option
          v-for="(data, index) in user.cabangList"
          :key="index"
          :value="data.kode"
        >
          Kode: {{ data.kode }}
        </a-select-option>
      </a-select>
      <a-popover
        placement="leftBottom"
        v-model="applistvisibility"
        trigger="click"
      >
        <template slot="content">
          <div class="row" style="width: 350px !important;">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pl-2 pr-2 mb-2 mt-2" v-for="(data, index) in user.appList" :key="index">
              <div class="mypopovercontent" :style="index === defaultAppValue ? 'background-color: lightgrey; border-radius: 4px;' : 'cursor: pointer;'" @click="selectApps(index)">
                <center>
                  <!-- <img
                    :src="data.icon_url == '' || data.icon_url == null ? './favicon.png' : data.icon_url"
                    alt=""
                    style="width: 50px; height: 50px;"
                  /> -->
                  <img v-if="data.icon_url == null" src="./favicon.png" alt="" style="width: 50px; height: 50px;">
                  <img v-else :src="data.icon_url" alt="" style="width: 50px; height: 50px;">
                </center>
                <center
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  <strong style="">{{ data.appName }}</strong>
                </center>
              </div>
            </div>
          </div>
        </template>
        <!-- <template slot="title">
          <span>Title</span>
        </template> -->
        <!-- <a-button class="mr-4" style="border-radius: 50%;border: none !important;"> -->
        <a href="javascript:;">
          <a-icon type="appstore" class="mr-4" style="font-size: 25px;" />
        </a>
        <!-- </a-button> -->
      </a-popover>
    </div>
    <div :style="settings.isMobileView ? 'width: 20%;' : ''">
      <cui-user-menu :class="settings.isMobileView ? 'float-right' : ''" />
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
// import mylottiecomp from '@/components/lou/lottie'
// import animationData from '@/assets/animations/12246-info.json'
// import CuiIssuesHistory from './IssuesHistory'
// import CuiProjectManagement from './ProjectManagement'
// import CuiSearch from './Search'
// import CuiLanguageSwitcher from './LanguageSwitcher'
// import CuiActions from './Actions'
// import clock from '@/components/lou/clock.vue'
import CuiUserMenu from './UserMenu'
import CuiFavPages from './FavPages'
import LouCariNasabah from './CariNasabah'
import store from 'store'
// import CuiBreadcrumbs from '@/components/cleanui/layout/Breadcrumbs'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    // CuiBreadcrumbs,
    CuiUserMenu,
    CuiFavPages,
    LouCariNasabah,
    // mylottiecomp,
  },
  watch: {
    'settings.whatapp': {
      handler: function (newValue, oldValue) {
        var indexTarget = this.user.appList.findIndex(
          (x) => x.id === newValue.id,
        )
        if (indexTarget >= 0) {
          this.defaultAppValue = indexTarget
        }
      },
      deep: true,
    },
  },
  // CuiBreadcrumbs
  // CuiIssuesHistory, CuiLanguageSwitcher, CuiActions,
  // CuiProjectManagement,
  // CuiSearch,
  // computed: {
  //   namabpr() {
  //     return this.$store.state.user.namabpr
  //   },
  //   sandibpr() {
  //     return this.$store.state.user.sandibpr
  //   },
  //   periode() {
  //     return this.$store.state.user.periode ? moment(this.$store.state.user.periode, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
  //   },
  // },
  data() {
    return {
      applistvisibility: false,
      // defaultOptions: { animationData: animationData },
      carinasabahvisible: false,
      carinasabahtitle: 'Cari Nasabah',
      defaultAppValue: null,
      editAble: false,
      dataCabangList: [],
      kodeCabang: localStorage.getItem('defaultsandikc') || 'all',
      input: {
        periode: moment(),
      },
      response: null,

      form: {
        uid: '',
        pin: '',
      },
      びっぇ: false,
      billeruidvisible: false,
    }
  },
  created() {
    // console.log('this.user.defaultApp', this.user.defaultApp)
    this.input.periode =
      moment(this.$store.state.user.periode, 'YYYY-MM-DD') || moment()
    this.dataCabangList = this.user.cabangList
    // console.log('this.user.defaultApp', this.user.defaultApp)
    this.settingDefaultApp()
  },
  methods: {
    moment,
    async settingBillerUid() {
      var value = this.form
      // console.log('value', value)
      var res = await lou.customUrlPut2('uid', value)
      if (res) {
        this.$store.commit('CHANGE_SETTING', {
          setting: 'tempAppData',
          value,
        })

        this.びっぇ = false
      } else {
        lou.shownotif('Error', 'Hubungi tim IT!', 'error')
      }
    },
    settingDefaultApp() {
      if (this.user.appList !== undefined && this.user.appList !== null) {
        var indexTarget = this.user.appList.findIndex(
          (x) => x.id === this.user.defaultApp.id,
        )
        if (indexTarget >= 0) {
          this.defaultAppValue = indexTarget
        }
      }
      this.form.uid = this.settings.tempAppData.uid
      this.form.pin = this.settings.tempAppData.pin
    },
    filterKodeCabang() {
      localStorage.removeItem('defaultsandikc')
      localStorage.setItem('defaultsandikc', this.kodeCabang)
    },
    toggleMobileMenu() {
      const value = !this.settings.isMobileMenuOpen
      // console.log('value', value)
      this.$store.commit('CHANGE_SETTING', {
        setting: 'isMobileMenuOpen',
        value,
      })
      // console.log('this.settings.isMobileMenuOpen', this.settings.isMobileMenuOpen)
    },
    async selectApps(e) {
      // console.log('e', e)
      var appList = this.$g.clone(this.user.appList)
      // console.log('appList[e]', appList[e])
      var res = await lou.customUrlGet(
        'auth/switchapp/' + appList[e].id,
        false,
        true,
      )
      if (res) {
        store.set('accessToken', res.data.token)
        var nperiode = res.data.tgl ? res.data.tgl : ''
        var nhideperiode =
          res.data.hide_periode !== undefined && res.data.hide_periode !== null
            ? res.data.hide_periode
            : false
        var ndefaultApp = this.$g.clone(appList[e])
        ndefaultApp.hide_periode = nhideperiode
        this.input.periode = moment(nperiode, 'YYYY-MM-DD')
        this.$store.commit('user/SET_STATE', {
          periode: nperiode,
          defaultApp: ndefaultApp,
        })
        localStorage.setItem(
          'userData',
          JSON.stringify(this.$store.state.user),
        )
        this.$store.commit('CHANGE_SETTING', {
          setting: 'whatapp',
          value: appList[e],
        })
        this.$router.push('/dashboard')
        this.applistvisibility = false
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    async getSettings() {
      var res = await lou.customUrlGet('auth/settings')
      this.input = res.data[0]
      // console.log('this.input', this.input)
      this.response = this.$g.clone(res.data[0])
    },
    async changePeriode() {
      // console.log('this.input.periode', this.input.periode)
      var nperiode = this.input.periode.format('YYYY-MM-DD')
      // await this.getSettings()
      var fd = { tgl: nperiode }
      // console.log('fd', fd)
      // fd.periode = nperiode
      // console.log('periode', nperiode)
      // console.log('fd', fd)
      // var res =
      var res = await lou.customUrlPost('auth/change_periode', fd, true, true)
      if (res) {
        this.input.periode = moment(nperiode, 'YYYY-MM-DD')
        this.$store.commit('user/SET_STATE', {
          periode: nperiode,
        })
        localStorage.setItem(
          'userData',
          JSON.stringify(this.$store.state.user),
        )
        // lou.shownotif('Success', '"Periode" has been successfully changed!')
        this.editAble = false
      }
    },
    handleAnimation(anim) {
      this.anim = anim
    },

    stop() {
      this.anim.stop()
    },

    play() {
      this.anim.play()
    },

    pause() {
      this.anim.pause()
    },

    onSpeedChange() {
      this.anim.setSpeed(this.animationSpeed)
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
