/* eslint-disable camelcase */
import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
// import * as lou from '@/services/data/lou'

// import * as firebase from '@/services/firebase'
import * as jwt from '@/services/jwt'

// import * as lou from '@/services/data/lou'

const mapAuthProviders = {
  // firebase: {
  //   login: firebase.login,
  //   register: firebase.register,
  //   currentAccount: firebase.currentAccount,
  //   logout: firebase.logout,
  // },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    name: '',
    email: '',
    kodesektor: '',
    sandibpr: '',
    namabpr: '',
    activeSessions: '',
    periode: '',
    KodeJenisPelapor: '',
    cabangList: [],
    permissions: [],
    appList: [],
    defaultApp: {},
    master: {},
    user_branch: '',
    // isAdmin: false,
    showheader: true,
    isHidden: false,
    authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    FRESHLOGIN({ commit, dispatch, rootState }, { payload }) {
      const { username, password, ip } = payload
      commit('SET_STATE', { loading: true })

      const login = mapAuthProviders[rootState.settings.authProvider].login
      login(username, password, ip, true).then((response) => {
        if (response) {
          // console.log('store->login->sukses')
          // dispatch('LOAD_CURRENT_ACCOUNT')
          // console.log('response login', response)
          dispatch('SET_RESPONSE', { response })
          Vue.prototype.$notification.success({
            message: 'Logged In',
            description: 'Anda berhasil login ke HEASOFT Banking System!',
            // description: 'Anda berhasil login ke HEASOFT LB-BPRS!',
          })
        }
        if (!response) {
          // Vue.prototype.$notification.success({
          //   message: 'Logged In',
          //   description: 'Anda berhasil login ke HEASOFT Banking System!',
          //   // description: 'Anda berhasil login ke HEASOFT LB-BPRS!',
          // })
          // console.log('store->login->gagal')
          commit('SET_STATE', { loading: false })
        }
      })
    },
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { username, password, ip } = payload
      commit('SET_STATE', { loading: true })

      const login = mapAuthProviders[rootState.settings.authProvider].login
      login(username, password, ip).then((response) => {
        if (response) {
          // console.log('store->login->sukses')
          // dispatch('LOAD_CURRENT_ACCOUNT')
          // console.log('response login', response)
          dispatch('SET_RESPONSE', { response })
          Vue.prototype.$notification.success({
            message: 'Logged In',
            description: 'Anda berhasil login ke HEASOFT Banking System!',
            // description: 'Anda berhasil login ke HEASOFT LB-BPRS!',
          })
        }
        if (!response) {
          // console.log('store->login->gagal')
          commit('SET_STATE', { loading: false })
        }
      })
    },
    SET_RESPONSE({ commit }, { response }) {
      // console.log('response', response)

      try {
        const {
          name,
          email,
          kodesektor,
          sandibpr,
          namabpr,
          periode,
          master,
          permissions,
          appList,
          defaultApp,
          user_branch,
          showheader,
          cabangList,
          KodeJenisPelapor,
        } = response
        const isHidden = response.Hidden !== undefined ? response.Hidden === 1 : false
        commit('SET_STATE', {
          name,
          email,
          kodesektor,
          sandibpr,
          namabpr,
          periode,
          master,
          permissions,
          appList,
          defaultApp,
          user_branch,
          cabangList,
          KodeJenisPelapor,
          showheader,
          isHidden,
          authorized: true,
        })
      } catch (e) {
        // console.log('e', e)
      }
      // lou.readId('v_users', id).then(response => {
      //   if (response) {
      //     console.log(response.data[0])
      //     const { city, cityName, role, group } = response.data[0]
      //     commit('SET_STATE', { city, cityName, role, group })
      //   }
      // })
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      // eslint-disable-next-line camelcase
      const {
        name,
        email,
        kodesektor,
        sandibpr,
        namabpr,
        periode,
        master,
        permissions,
        appList,
        defaultApp,
        user_branch,
        showheader,
        cabangList,
        KodeJenisPelapor,
      } = payload
      commit('SET_STATE', { loading: true })

      const register =
        mapAuthProviders[rootState.settings.authProvider].register
      register(
        name,
        email,
        kodesektor,
        sandibpr,
        namabpr,
        periode,
        master,
        KodeJenisPelapor,
        permissions,
        appList,
        defaultApp,
        user_branch,
        showheader,
        cabangList,
      ).then((response) => {
        if (response) {
          dispatch('SET_RESPONSE', { response })
          Vue.prototype.$notification.success({
            message: 'Succesful Registered',
            description: 'You have successfully registered!',
          })
          router.push('/')
        }
        if (!response) {
          commit('SET_STATE', { loading: false })
        }
      })
    },
    LOAD_CURRENT_ACCOUNT({ commit, dispatch, rootState }) {
      commit('SET_STATE', { loading: false })
      const currentAccount =
        mapAuthProviders[rootState.settings.authProvider].currentAccount
      currentAccount().then((response) => {
        // console.log('response', response)
        if (response) {
          var userData = localStorage.getItem('userData')
          if (userData !== undefined || userData !== null) {
            var jsoned = JSON.parse(userData)
            // console.log('jsoned', jsoned)

            try {
              if (jsoned.permissions !== undefined) delete jsoned.permissions
            } catch (e) {
              // console.log('e', e)
            }

            try {
              if (jsoned.appList !== undefined) delete jsoned.appList
            } catch (e) {
              // console.log('e', e)
            }
            dispatch('SET_RESPONSE', { response: jsoned })
          }
        }
        commit('SET_STATE', { loading: false })
      })
    },
    LOGOUT({ commit, rootState }) {
      const logout = mapAuthProviders[rootState.settings.authProvider].logout
      logout().then(() => {
        commit('SET_STATE', {
          name: '',
          email: '',
          kodesektor: '',
          sandibpr: '',
          namabpr: '',
          periode: '',
          KodeJenisPelapor: '',
          permissions: [],
          appList: [],
          defaultApp: {},
          user_branch: '',
          cabangList: [],
          loading: false,
          isHidden: false,
          showheader: true,
          authorized: false, // false is default value
        })
        // lou.dropDb()
        localStorage.clear()
        // location.reload()
        // localStorage.removeItem('userData')
        router.push('/auth/login')
      })
    },
  },
  getters: {
    user: (state) => state,
  },
}
