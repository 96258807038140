<template>
  <a-dropdown
    v-model="visible"
    placement="bottomLeft"
    @visibleChange="addCount"
  >
    <div :class="$style.dropdown">
      <a-badge>
        <!-- :count="count"> -->
        <a-avatar
          shape="square"
          icon="user"
          size="large"
          :class="$style.avatar"
        />
      </a-badge>
    </div>
    <a-menu slot="overlay">
      <a-menu-item>
        <div>
          <strong
            >{{ $t("topBar.profileMenu.hello") }},
            {{ user.name || "Anonymous" }}</strong
          >
        </div>
        <!-- <div class="textku">
          <strong>Toko: </strong> {{ user.ukmsys.toko.nama }}
        </div> -->
        <div>
          <strong class="mr-1">{{ $t("topBar.profileMenu.role") }}:</strong>
          {{ user.role || "—" }}
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <div>
          <strong class="mr-1">{{ $t("topBar.profileMenu.email") }}:</strong>
          {{ user.email || "—" }}
        </div>
        <div>
          <strong class="mr-1">{{ $t("topBar.profileMenu.phone") }}:</strong> —
        </div>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a
          href="javascript: void(0);"
          v-if="!input.visibleinputname"
          @click="input.visibleinputname = !input.visibleinputname"
        >
          <i class="fe fe-user mr-2"></i>
          Change Name
        </a>
        <a-input
          type=""
          v-if="input.visibleinputname"
          v-model="input.name"
          placeholder="Change Name"
          @keydown.enter="changeName"
        >
          <a-icon
            slot="suffix"
            type="close"
            @click="input.visibleinputname = !input.visibleinputname"
          />
        </a-input>
      </a-menu-item>
      <a-menu-item>
        <a
          href="javascript: void(0);"
          v-if="!input.visibleinputpass"
          @click="input.visibleinputpass = !input.visibleinputpass"
        >
          <i class="fe fe-user mr-2"></i>
          Change Password
        </a>
        <a-input
          type=""
          v-if="input.visibleinputpass"
          v-model="input.password"
          placeholder="Change Password"
          @keydown.enter="changePassword"
        >
          <a-icon
            slot="suffix"
            type="close"
            @click="input.visibleinputpass = !input.visibleinputpass"
          />
        </a-input>
        <!-- <a-input type="" v-if="input.visibleinputpass" v-model="input.repassword" placeholder="Re-type Password" @keydown.enter="changePassword">
          <a-icon slot="suffix" type="close" @click="input.visibleinputpass = !input.visibleinputpass"/>
        </a-input> -->
      </a-menu-item>
      <a-menu-item v-if="user.isHidden">
        <a
          class="text-danger"
          href="javascript: void(0);"
          v-if="!input.visibleinputswitchsandibprs"
          @click="changevisibilityswitchsandi"
        >
          <i class="fe fe-settings mr-2"></i>
          Change sandi BPRS
        </a>
        <a-select
          v-if="input.visibleinputswitchsandibprs"
          v-model="input.switchsandibprs"
          style="width: 100%;"
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          @dropdownVisibleChange="checkselect"
          @change="switchSandiBPRS"
        >
          <a-select-option
            v-for="(data, index) in input.switchdata"
            :key="index"
            :value="data.id"
            >{{ data.sandibpr + ' - ' + data.namabpr }}</a-select-option
          >
        </a-select>
      </a-menu-item>
      <!-- <a-menu-item @click="visible = true">
        <a-popover title="Change Password" v-model="input.visibleinputpass" trigger="click" style="z-index: 999 !important">
          <template slot="content">
            <label for="password">New Password</label>
            <br>
            <a-input type="text" v-model="input.password" style="width: 200px"/>
            <br>
            <label for="repassword">Re-Type Password</label>
            <br>
            <a-input type="text" v-model="input.repassword" style="width: 200px"/>
            <br>
            <strong class="text-danger mt-2" style="cursor: pointer;" @click="input.visibleinputpass = !input.visibleinputpass">Cancel</strong>
            <strong class="text-primary mt-2 ml-2" style="cursor: pointer;" @click="changePassword">Submit</strong>
          </template>
          <i class="fe fe-user mr-2"></i>
          Change Password
        </a-popover>
        <a-input type="" v-if="input.visibleinputpass" v-model="input.password" placeholder="Change Password" @keydown.enter="changePassword">
          <a-icon slot="suffix" type="close" @click="input.visibleinputpass = !input.visibleinputpass"/>
        </a-input>
        <br>
        <a-input type="" v-if="input.visibleinputpass" v-model="input.repassword" placeholder="Re-type Password" @keydown.enter="changePassword">
          <a-icon slot="suffix" type="close" @click="input.visibleinputpass = !input.visibleinputpass"/>
        </a-input>
      </a-menu-item> -->
      <a-menu-divider />
      <a-menu-item>
        <a href="javascript: void(0);" @click="toggleSetting">
          <i class="fe fe-settings mr-2"></i>
          {{
            settings.isSettingOpen === true
              ? "Hide Setting Bar"
              : "Show Setting Bar"
          }}
        </a>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item>
        <a href="javascript: void(0);" @click="logout">
          <i class="fe fe-log-out mr-2"></i>
          {{ $t("topBar.profileMenu.logout") }}
        </a>
      </a-menu-item>
      <editprofile ref="editprofile" />
    </a-menu>
  </a-dropdown>
</template>

<script>
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import editprofile from '@/components/lou/edituser.vue'
import store from 'store'

export default {
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  // watch: {
  //   'input.visibleinputpass'(newValue, oldValue) {
  //     if (newValue) {
  //       this.visible = true
  //     }
  //   },
  // },
  created() {
    // this.roletags = this.user.role.includes('Owner')
    // this.getKota()
  },
  components: {
    editprofile,
  },
  data: function () {
    return {
      visible: false,
      count: 7,
      input: {
        visibleinputname: false,
        name: '',
        visibleinputpass: false,
        password: '',
        repassword: '',
        visibleinputswitchsandibprs: false,
        switchdata: [],
        switchsandibprs: '',
      },
      selectvisibility: false,
      roletags: false,
      storename: [],
    }
  },
  watch: {
    visible(newValue, oldValue) {
      if (!newValue) {
        if (this.selectvisibility) {
          this.visible = true
        }
      }
    },
  },
  methods: {
    checkselect(open) {
      this.selectvisibility = open
      // console.log('open', open)
    },
    async switchSandiBPRS() {
      // console.log('this.$store.user', this.$store.state.user)
      var res = await lou.customUrlPost('auth/switchbpr/' + this.input.switchsandibprs, {}, true, true)
      if (res) {
        var response = res.data
        this.$store.dispatch('user/SET_RESPONSE', { response })
        // this.$store.state.user.name = res.data.name
        // this.$store.state.user.email = res.data.email
        // this.$store.state.user.kodesektor = res.data.kodesektor
        this.$store.state.user.sandibpr = res.data.sandibpr
        this.$store.state.user.namabpr = res.data.namabpr
        this.$store.state.user.periode = res.data.periode
        this.$store.state.user.permissions = res.data.permissions
        this.$store.state.user.appList = res.data.appList
        this.$store.state.user.defaultApp = res.data.defaultApp
        // this.$store.state.user.isHidden = res.data.Hidden !== undefined ? res.data.Hidden === 1 : false
        this.$store.state.user.showheader = res.data.showheader
        this.$store.state.user.cabangList = res.data.cabangList
        store.set('accessToken', res.data.token)
        localStorage.setItem('userData', JSON.stringify(res.data))
        this.input.visibleinputswitchsandibprs = false
      }
    },
    async changevisibilityswitchsandi() {
      var res = await lou.customUrlGet('auth/sandibpr', false, true)
      if (!res.isError) {
        this.input.switchsandibprs = this.user.sandibpr
        this.input.switchdata = res.data
        this.input.visibleinputswitchsandibprs = true
      }
    },
    async changeName() {
      var fd = {
        name: this.input.name,
      }
      var res = await lou.customUrlPut('auth', fd, true, true)
      if (res) {
        this.input.visibleinputname = false
        this.$store.state.user.name = fd.name
      }
    },
    async changePassword() {
      // if (this.input.password === this.input.repassword) {
      var fd = {
        password: this.input.password,
      }
      var res = await lou.customUrlPut('auth/changepassword', fd)
      if (res) {
        this.input.visibleinputpass = false
      }
      // } else {
      //   lou.shownotif('Warning!', 'Password tidak sama!', 'warning')
      // }
    },
    addCount() {
      this.count++
    },
    logout() {
      this.settings.selectedRekening = ''
      this.$store.dispatch('user/LOGOUT')
    },
    toggleSetting: function () {
      const setting = 'isSettingOpen'
      // const value = !this.$store.state.settings.isSettingOpen ==> pakai ini juga bisa, jadi tanpa mapState[setting], tapi nulisnya agak panjang
      const value = !this.settings[setting]
      this.$store.commit('CHANGE_SETTING', { setting, value })
    },
    // async getKota() {
    //   var res = await isa.read('store', { where: { id: this.user.storeId } })
    //   this.storename = res.data
    // },
    edituser() {
      // console.log('this.$refs', this.$refs)
      this.$refs.editprofile.storename = this.storename
      this.$refs.editprofile.showModal()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
.textku {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-popover {
  z-index: 99999 !important;
}
</style>
