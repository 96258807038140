import apiClient from '@/services/axios'
import * as lou from '@/services/data/lou'
import store from 'store'
// import vuexstore from '@/store'

export async function login(email, password, ip, fresh = false) {
  var link = '/'
  var tambahan = fresh ? '?fresh=true' : ''
  // var device = {
  //   name: navigator.appName,
  //   version: navigator.appVersion,
  //   type: 'web',
  // }
  var data = {
    email,
    password,
    ip,
    // device,
  }
  apiClient.defaults.baseURL = 'https://apilbbprs.heasoft.com/api'
  return apiClient
    .post(link + 'auth/login' + tambahan, data)
    .then(async (response) => {
      // const tes = response
      // console.log('sukses service->jwt->login', response.data.data.token)
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        const accessToken = response.data.data.token
        if (accessToken) {
          var data = response.data.data
          functionAfterLogin(fresh, data)
          // console.log('data', data)
          // console.log(accessToken)
          store.set('accessToken', accessToken)
          // localStorage.setItem('userData', JSON.stringify(data))
          // console.log('response.data.data.defaultApp.appName', response.data.data.defaultApp.appName.toLowerCase())
          localStorage.setItem('permissions' + data.defaultApp.appName.toLowerCase() + data.showheader.toString(), JSON.stringify(data.permissions))
          // console.log('localStorage.getItem("permissions")', localStorage.getItem('permissions'))
          // var dataIndexed = await lou.getDataFromDb()
          // console.log('dataIndexed', dataIndexed)
          // var dashboardData = {
          //   mykey: 'allGridConfig' + response.data.data.defaultApp.appName,
          //   data: JSON.stringify(response.data.data.gridConfig),
          // }
          // await lou.addDataToDb(dashboardData)
          // if (response.data.data) {
          //   var ndata = response.data.data
          //   delete ndata.permissions
          //   store.set('userData', ndata)
          // }
          // this.$router.push('/gaji/dashboard/view')
        }
        // console.log('response', response)
        return data
      }
      return false
    })
    .catch(err => console.log('err service->jwt->login', err.data))
}

async function functionAfterLogin(fresh, data) {
  // console.log('fresh', fresh)
  // console.log('data', data)
  if (fresh) {
    var nindexeddb1 = await lou.getDataFromDb()
    var gcIndexTarget1 = nindexeddb1.findIndex(x => x.mykey === 'allGridConfig' + data.defaultApp.appName + data.defaultApp.id)
    var mIndexTarget1 = nindexeddb1.findIndex(x => x.mykey === 'allmaster' + data.defaultApp.appName.toLowerCase() + data.defaultApp.id)
    if (gcIndexTarget1 >= 0) {
      lou.deleteDataFromDb(nindexeddb1[gcIndexTarget1].id)
    }
    if (mIndexTarget1 >= 0) {
      lou.deleteDataFromDb(nindexeddb1[mIndexTarget1].id)
    }
    if (data.gridConfig !== undefined) {
      var gridConfigData = {
        mykey: 'allGridConfig' + data.defaultApp.appName + data.defaultApp.id,
        data: JSON.stringify(data.gridConfig),
      }
    }
    lou.addDataToDb(gridConfigData)
    if (data.master !== undefined) {
      var masterData = {
        mykey: 'allmaster' + data.defaultApp.appName.toLowerCase() + data.defaultApp.id,
        data: JSON.stringify(data.master),
      }
      lou.addDataToDb(masterData)
    }
  } else {
    var nindexeddb = await lou.getDataFromDb()
    var gcIndexTarget = nindexeddb.findIndex(x => x.mykey === 'allGridConfig' + data.defaultApp.appName + data.defaultApp.id)
    var mIndexTarget = nindexeddb.findIndex(x => x.mykey === 'allmaster' + data.defaultApp.appName.toLowerCase() + data.defaultApp.id)
    // console.log('nindexeddb', nindexeddb)
    if (gcIndexTarget >= 0) {
      data.gridConfig = JSON.parse(nindexeddb[gcIndexTarget].data)
    }
    if (mIndexTarget >= 0) {
      data.master = JSON.parse(nindexeddb[mIndexTarget].data)
    }
  }
}

// eslint-disable-next-line camelcase
export async function register(
  name,
  username,
  email,
  password,
  departement,
  jabatan,
  toleransi,
  timezone,
  longitude,
  latitude,
  akurasi,
) {
  var link = '/'
  apiClient.defaults.baseURL = 'https://apilbbprs.heasoft.com/api'
  return apiClient
    .post(link + 'register', {
      name,
      username,
      email,
      password,
      departement,
      jabatan,
      toleransi,
      timezone,
      longitude,
      latitude,
      akurasi,
    })
    .then(response => {
      if (response) {
        // console.log('response.data', response.data)
        const accessToken = response.data.data.token
        if (accessToken) {
          store.set('accessToken', accessToken)
          store.set('userData', response.data.data)
        }
        return response.data.data
      }
      return false
    })
    .catch(err => console.log('err service->jwt->register', err.data))
}

export async function currentAccount() {
  var link = '/'
  // var data = {}
  apiClient.defaults.baseURL = 'https://apilbbprs.heasoft.com/api'
  return apiClient
    .get(link + 'auth/validate')
    .then(response => {
      // console.log('service->jwt->currentAccount', response)
      if (response) {
        return response.data.data
      }
      return false
    })
    .catch(err => console.log('service->jwt->currentAccount', err))
}

export async function logout() {
  // return apiClient
  //   .get('/auth/logout')
  //   .then(() => {
  //     store.remove('accessToken')
  //     return true
  //   })
  //   .catch(err => console.log(err))
  store.remove('accessToken')
  return true
}
