export var getMenuData = [
  // {
  //   category: true,
  //   title: 'Dashboards',
  // },
  // {
  //   title: 'Dashboard',
  //   key: 'dashboard',
  //   url: '/dashboard/main',
  //   icon: 'fe fe-home',
  // },
  // {
  //   title: 'Jaminan Pemohon',
  //   icon: 'fe fe-grid',
  //   children: [
  //     {
  //       title: 'List pemohon',
  //       key: 'ListPemohon',
  //       url: '/pemohon/list',
  //       icon: 'fe fe-user',
  //     },
  //     {
  //       title: 'BPKB',
  //       key: 'taksasiPinjamanBPKB',
  //       url: '/taksasi/bpkb',
  //       icon: 'fe fe-book',
  //     },
  //     {
  //       title: 'SHM',
  //       key: 'taksasiPinjamanSHM',
  //       url: '/taksasi/shm',
  //       icon: 'fe fe-book-open',
  //     },
  //   ],
  // },
  // {
  //   title: 'Users',
  //   key: 'users',
  //   url: '/master/users',
  //   icon: 'fe fe-users',
  // },
  // {
  //   title: 'Permissions',
  //   key: 'permissions',
  //   url: '/master/permissions',
  //   icon: 'fe fe-settings',
  // },
  // {
  //   category: true,
  //   title: 'Laporan',
  // },
  // {
  //   title: 'LB-BPRS',
  //   key: 'LB-BPRS',
  //   icon: 'fe fe-grid',
  //   children: [
  //     {
  //       title: 'Form BPKB',
  //       key: 'taksasiPinjamanBPKBform',
  //       url: '/taksasi/bpkb/form',
  //       icon: 'fa fa-book',
  //     },
  //     {
  //       title: 'Form SHM',
  //       key: 'taksasiPinjamanSHMform',
  //       url: '/taksasi/shm/form',
  //       icon: 'fa fa-files-o',
  //     },
  //   ],
  // },
  // {
  //   category: true,
  //   title: 'Setting',
  // },
  // {
  //   title: 'Settings',
  //   key: 'userList',
  //   icon: 'fe fe-users',
  //   children: [
  //     {
  //       title: 'Users',
  //       key: 'daftarUser',
  //       url: '/master/users',
  //       icon: 'fa fa-user',
  //       // permissions: ['access-users'],
  //     },
  //     {
  //       title: 'Roles',
  //       key: 'roleGroup',
  //       url: '/master/roles',
  //       icon: 'fa fa-key',
  //       permissions: ['access-roles'],
  //     },
  //     {
  //       title: 'Permissions',
  //       key: 'permissionGroup',
  //       url: '/master/permissions',
  //       icon: 'fa fa-cog',
  //       roles: ['super admin'],
  //     },
  //   ],
  // },
  // {
  //   title: 'Master Data',
  //   key: 'masterData',
  //   icon: 'fe fe-grid',
  //   children: [
  // {
  //   title: 'Pelanggan',
  //   key: 'pelanggan',
  //   url: '/master/pelanggan',
  //   icon: 'fa fa-users',
  // },
  //   ],
  // },
]
