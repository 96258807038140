import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import NProgress from 'vue-nprogress'
// import vuetify from '@/src/plugins/vuetify' // path to vuetify export
import VueLayers from 'vuelayers'
import BootstrapVue from 'bootstrap-vue'
import VueTour from 'vue-tour'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './localization'
import UUID from 'vue-uuid'
import './antd'
import './registerServiceWorker'
import VueHighlightJS from 'vue-highlightjs'
// import MarkdownItToc from 'vue-markdown-it-toc'

import firebase from 'firebase/app'
import 'firebase/storage'
// import 'firebase/analytics'
import 'firebase/messaging'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Vue editable grid component and styles
// import VueEditableGrid from 'vue-editable-grid'
// import 'vue-editable-grid/dist/VueEditableGrid.css'

// Socket IO
import io from 'socket.io-client'

// mocking api
// import './services/axios/fakeApi'
import 'leaflet/dist/leaflet.css'

import * as ajiVer from '../system_info/aj.json'
import * as isaVer from '../system_info/isa.json'
import * as loVer from '../system_info/lou.json'
import * as puVer from '../system_info/pu.json'

import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css'

import * as GmapVue from 'vue2-google-maps'
import { FormModel } from 'ant-design-vue'
import L from 'leaflet'

import Lottie from 'vue-lottie'

import IdleVue from 'idle-vue'
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
require('vue-tour/dist/vue-tour.css')

const versionCode = 'LOU' + loVer.version.patch + 'ISA' + isaVer.version.patch + 'AJ' + ajiVer.version.patch + puVer.version.patch
Vue.prototype.$versionCode = versionCode
window.io = io
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3000,
  startAtIdle: false,
  events: [
    'mousemove',
    'keydown',
    'mousedown',
    'touchstart',
    'touchmove',
    'mousewheel',
  ],
})

Vue.component('lottie', Lottie)
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyAWH2MpCOl1M8wbWup_q5aXQBrjLlqLTVM',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    /// / If you want to set the version, you can do so:
    // v: '3.26',
  },

  /// / If you intend to programmatically custom event listener code
  /// / (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  /// / instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  /// / you might need to turn this on.
  // autobindAllEvents: false,

  /// / If you want to manually install components, e.g.
  /// / import {GmapMarker} from 'gmap-vue/src/components/marker'
  /// / Vue.component('GmapMarker', GmapMarker)
  /// / then set installComponents to 'false'.
  /// / If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
})

// register component in the Vue app
Vue.component('ag-grid-vue', AgGridVue)
Vue.component('v-select', vSelect)
Vue.use(BootstrapVue)
Vue.use(VueLayers)
Vue.use(FormModel)
Vue.use(UUID)
Vue.use(VueTour)
Vue.use(NProgress)
Vue.use(VuePageTitle, {
  prefix: (location.host.indexOf('lb.heasoft') >= 0 ? 'LB App | ' : location.host.indexOf('localhost') >= 0 ? '' : 'LB App | '),
  router,
})
// Vue.use(MarkdownItToc)
// Tell Vue.js to use vue-highlightjs
Vue.use(VueHighlightJS)

Vue.config.productionTip = false
var firebaseConfig = {
  apiKey: 'AIzaSyDXdstZEQG0V-Z5WjYNbN_ooOTnhal4SJ8',
  authDomain: 'jaminan.firebaseapp.com',
  projectId: 'jaminan',
  storageBucket: 'jaminan.appspot.com',
  messagingSenderId: '839719445693',
  appId: '1:839719445693:web:b6ed62233891c1559dc3d4',
}
firebase.initializeApp(firebaseConfig)
// firebase.analytics()
firebase.storage()
navigator.serviceWorker.register('firebase-messaging-sw.js', { scope: 'firebase-cloud-messaging-push-scope' })
  .then((registration) => {
    const messaging = firebase.messaging()
    messaging.useServiceWorker(registration)
    messaging.getToken({ vapidKey: 'BB2n8bQLgxpBbL12ogITLJqACn125xS9XW-pUD0r_Egl-3YSkjEyxbft6Gn1FS3-VznJrs6K5s2YRG6_ReaJsW8' }).then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // console.log('currentToken', currentToken)
        localStorage.setItem('fcmToken', currentToken)
        // console.log('currentToken', currentToken)
        // ...
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.')
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // ...
    })
  }).catch(err => {
    console.log(err)
  })
const nprogress = new NProgress({ parent: 'body' })

new Vue({
  router,
  store,
  nprogress,
  // vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')

Vue.prototype.$perpus = {
  searchObj: (objs, key, value) => {
    var sol = []
    for (var i in objs) {
      // eslint-disable-next-line no-prototype-builtins
      if (objs[i].hasOwnProperty(key) && objs[i][key] === value) {
        sol.push(objs[i])
      }
    }
    // return sol;
    // var index = sol.indexOf(value);
    return sol
  },
  findOrFail: (objs, key, value) => {
    var sol = []
    for (var i in objs) {
      // eslint-disable-next-line no-prototype-builtins
      if (objs[i].hasOwnProperty(key)) {
        sol.push(objs[i][key])
      }
    }
    // return sol;
    // console.log([sol, sol.indexOf(2), value]);
    var index = sol.indexOf(value)
    return objs[index]
  },
}

Vue.prototype.$g = {
  clone: (obj) => {
    return JSON.parse(JSON.stringify(obj))
  },
}
