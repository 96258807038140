<template>
  <a-layout-sider
    :width="settings.leftMenuWidth"
    :collapsible="settings.isMobileView ? false : true"
    :collapsed="settings.isMenuCollapsed && !settings.isMobileView"
    @collapse="onCollapse"
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
      [$style.unfixed]: settings.isMenuUnfixed,
      [$style.shadow]: settings.isMenuShadow,
    }"
  >
    <div
      :class="$style.menuOuter"
      :style="{
        width:
          settings.isMenuCollapsed && !settings.isMobileView
            ? '80px'
            : settings.leftMenuWidth + 'px',
        height:
          settings.isMobileView || settings.isMenuUnfixed
            ? 'calc(100% - 64px)'
            : 'calc(100% - 110px)',
      }"
    >
      <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <img style="height:24px; width:24px;" :src="settings.hosturl === 'kop.heasoft.com' ? 'resources/images/logo.png' : settings.whatapp !== null && Object.keys(settings.whatapp).length > 0 ? settings.whatapp.icon_url : 'resources/images/logo.png'" class="mr-2 customiconclass" :alt="settings.hosturl === 'kop.heasoft.com' ? 'Koperasi System' : settings.whatapp !== null && Object.keys(settings.whatapp).length > 0 ? settings.whatapp.appName : 'Banking App'" />
          <!-- <div :class="$style.name">Banking System</div> -->
          <div :class="$style.name">{{ settings.hosturl === 'kop.heasoft.com' ? 'Koperasi System' : settings.whatapp !== null && Object.keys(settings.whatapp).length > 0 ? settings.whatapp.appName : 'Banking App' }}</div>
          <!-- <div v-if="settings.logo === 'KSP-Syariah'" :class="$style.descr"></div> -->
        </div>
      </div>
      <vue-custom-scrollbar
        :style="{
          height:
            settings.isMobileView || settings.isMenuUnfixed
              ? 'calc(100vh - 64px)'
              : 'calc(100vh - 110px)',
        }"
      >
        <a-menu
          :key="componentKey"
          forceSubMenuRender
          :inlineCollapsed="
            settings.isMobileView ? false : settings.isMenuCollapsed
          "
          :mode="'inline'"
          :selectedKeys="selectedKeys"
          :openKeys.sync="openKeys"
          @click="handleClick"
          @openChange="handleOpenChange"
          :inlineIndent="15"
          :class="$style.navigation"
        >
          <template v-for="(item, index) in menuData">
            <template v-if="!item.roles">
              <a-menu-item-group :key="index" v-if="item.category">
                <template slot="title">{{ item.title }}</template>
              </a-menu-item-group>
              <item
                v-if="item.children.length === 0 && !item.category"
                :menu-info="item"
                :styles="$style"
                @click="toggleMobileMenu"
                :key="item.key"
              />
              <sub-menu
                v-if="item.children.length !== 0"
                :menu-info="item"
                :styles="$style"
                @click="toggleMobileMenu"
                :key="item.key"
              />
            </template>
          </template>
        </a-menu>
        <div :class="$style.banner">
          <p>
            Licensed to
            <br />{{ user.namabpr === '' ? laksdfjiw ? 'DREO System' : 'Heasoft Banking System' : user.namabpr }}
          </p>
          <a
            href="javascript:void(0)"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-sm btn-success btn-rounded px-3"
            >{{ user.namabpr === '' ? laksdfjiw ? 'DREO System' : 'Heasoft Banking System' : user.namabpr }}
            </a
          >
        </div>
      </vue-custom-scrollbar>
    </div>
  </a-layout-sider>
</template>

<script>
import * as lou from '@/services/data/lou'
import { mapState, mapGetters } from 'vuex'
import store from 'store'
import find from 'lodash/find'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import { getMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'menu-left',
  components: { vueCustomScrollbar, SubMenu, Item },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.openKeys = store.get('app.menu.openedKeys') || []
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    // console.log('this.$store.state.user.permissions', this.$store.state.user.permissions)
    if (location.hostname.includes('dreo')) {
      // console.log('menu left location.hostname', location.hostname)
      this.laksdfjiw = true
    }
    this.getAppList()
    this.setSelectedKeys()
  },
  data() {
    return {
      laksdfjiw: false,
      menuData: getMenuData,
      selectedKeys: [],
      openKeys: [],
      rawMenuData: [],
      componentKey: 0,
    }
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    'settings.whatapp'() {
      // console.log('this.settings.whatapp', this.settings.whatapp)
      this.changeTabIcon()
      this.getDataMenu()
    },
    $route() {
      this.setSelectedKeys()
    },
  },
  methods: {
    // Tab Icon Browser Change
    changeTabIcon() {
      var lists = document.querySelectorAll('link')
      // console.log('lists', lists)

      var filteredList = Array.prototype.filter.call(lists, function(node) {
        // console.log('node', node)
        return node.rel === 'icon'
      })
      // console.log('filteredList', filteredList)
      // console.log('window.location.origin', window.location.origin)
      var that = this
      filteredList.forEach(element => {
        element.href = that.settings.whatapp !== null && Object.keys(that.settings.whatapp).length > 0 ? that.settings.whatapp.icon_url : window.location.origin + '/favicon.png'
      })
      // setTimeout(() => {
      //   filteredList.forEach(element => {
      //     element.href = window.location.origin
      //   })
      // }, 1000)
    },
    async getDataMenu() {
      // var link = document.querySelector("link[rel~='icon']")
      // if (!link) {
      //   link = document.createElement('link')
      //   link.rel = 'icon'
      //   document.getElementsByTagName('head')[0].appendChild(link)
      // }
      // link.href = this.settings.whatapp.icon_url
      // console.log('getdataMenu')
      // console.log('this.user.permissions', this.user.permissions)
      // console.log('this.user.showheader.toString()', this.user.showheader.toString())
      try {
        var permissions = JSON.parse(localStorage.getItem('permissions' + this.settings.whatapp.appName.toLowerCase() + this.user.showheader.toString()))
      } catch (e) {
        // console.log('e', e)
      }
      // console.log('this.settings.whatapp.appName.toLowerCase()', this.settings.whatapp.appName.toLowerCase())
      // console.log('permissions', permissions)
      if (permissions === 'undefined' || permissions === null || permissions.length === 0) {
        var resp = await lou.customUrlGet('auth/permissions', false, true)
        if (resp) {
          localStorage.setItem('permissions' + this.settings.whatapp.appName.toLowerCase() + this.user.showheader.toString(), JSON.stringify(resp.data))
        }
        this.rawMenuData = resp.data
      } else {
        this.rawMenuData = permissions
      }
      this.user.permissions = this.rawMenuData
      this.pushMenu()
    },
    getAppList() {
      // if (this.settings.whatapp.appName.toLowerCase() === 'LBBPRS') {
      this.$store.commit('CHANGE_SETTING', { setting: 'whatapp', value: this.user.defaultApp })
      // }
      // console.log('localStorage.getItem(appList)', localStorage.getItem('appList'))
      var appList = localStorage.getItem('appList')
      // console.log('this.user.appList', this.user.appList)
      // console.log('appList', appList)
      if (appList === undefined || appList === null) {
        localStorage.setItem('appList', JSON.stringify(this.user.appList))
      } else {
        this.user.appList = JSON.parse(appList)
      }
      this.getDataMenu()
    },
    pushMenu() {
      var oldData = {}
      // var menuDouble = 0
      var ndata = []
      for (let i = 0; i < this.rawMenuData.length; i++) {
        const element = this.rawMenuData[i]
        // console.log('element', element)
        if (element === oldData) {
          // menuDouble += 1
        } else {
          ndata.push(element)
        }
        oldData = element
      }
      // console.log('Menu Double: ', menuDouble)
      this.menuData = []
      this.menuData = ndata
      // this.componentKey += 1
    },
    onCollapse: function (collapsed, type) {
      const value = !this.settings.isMenuCollapsed
      this.$store.commit('CHANGE_SETTING', {
        setting: 'isMenuCollapsed',
        value,
      })
    },
    handleClick(e) {
      if (e.key === 'settings') {
        this.$store.commit('CHANGE_SETTING', {
          setting: 'isSettingsOpen',
          value: true,
        })
        return
      }
      store.set('app.menu.selectedKeys', [e.key])
      this.selectedKeys = [e.key]
    },
    handleOpenChange(openKeys) {
      store.set('app.menu.openedKeys', openKeys)
      this.openKeys = openKeys
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])
      this.selectedKeys = selectedItem ? [selectedItem.key] : []
    },
    toggleMobileMenu() {
      const value = !this.settings.isMobileMenuOpen
      this.$store.commit('CHANGE_SETTING', {
        setting: 'isMobileMenuOpen',
        value,
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
